import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import 'bulma/css/bulma.css'

import './App.css'
import Login from "./login";
import Index from './index'
import Meeting from './meeting'
import { AuthProvider } from "./Auth";
import PrivateRoute from "./PrivateRoute";

class App extends Component {
  render() {
    return (
      <AuthProvider>
      <Router>
        <div className="full">
          <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="/" component={Index} />
          <PrivateRoute path="/meeting" component={Meeting} />
        </div>
      </Router>
      </AuthProvider>
    )
  }
}

export default App
