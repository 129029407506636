export default {
  minLength(value, length) {
    return value.length < length ?
      1 : 0
  },
  maxLength(value, length) {
    return value.length > length ?
      1 : 0
  },
  validChar(value) {
    return !/^[a-zA-Z0-9]*$/.test(value) ?
      1 : 0
  },
  validEmail(value) {
    return !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(value) ?
      1 : 0
  },
  isNonEmpty(value) {
    return value === '' ?
      1 : 0
  }
}