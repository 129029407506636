import React from "react";
import * as Cookies from "js-cookie";
import * as firebase from "firebase/app";

import "./meeting.css";
import AgoraVideoCall from "../../components/AgoraVideoCall";
import { AGORA_APP_ID } from "../../agora.config";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import AgoraCanvas from "../../components/AgoraVideoCall";
import app from "./../../utils/firebase";
const db = app.firestore();


class Meeting extends React.Component {
  constructor() {
    super();
    this.videoProfile = Cookies.get("videoProfile").split(",")[0] || "480p_4";
    this.channel = Cookies.get("channel") || "test";
    this.outcome = Cookies.get('outcome') ?? '';
    this.transcode = Cookies.get("transcode") || "interop";
    this.attendeeMode = Cookies.get("attendeeMode") || "video";
    this.baseMode = Cookies.get("baseMode") || "avc";
    this.appId = AGORA_APP_ID;
    this.sessionId = Cookies.get('sessionId');
    this.expertId = Cookies.get('expertId');
    this.clientId = Cookies.get('clientId');
    this.txnId = Cookies.get('txnId');
    this.fee = Number.parseInt(Cookies.get('fee'));
    this.isFreeSession = Cookies.get('isFreeSession');
    this.meetingDuration = Number.parseInt(Cookies.get('meetingDuration'));
    this.totalMeetings = Number.parseInt(Cookies.get('totalMeetings'));
    this.meetingRemaining = Number.parseInt(Cookies.get('meetingRemaining'));
    this.meetingCompleted = Number.parseInt(Cookies.get('meetingCompleted'));
    this.isExpert = Cookies.get('isExpert');

    this.state = {
      countDown: '00:00:00',
      elapsedMinutes: 0,
      open: false,
      isSessionCancelBtn: true,
      scroll: 'paper',
    };

    if (!this.appId) {
      return alert("Get App ID first!");
    }
    this.uid = undefined;
  }

  handleClickOpen = () => {
    console.log('opened');
    this.setState({ 
      open: true,
      isSessionCancelBtn: true 
    })

  };

  handleClose = () => {
    this.setState({ open: false })
  };

  handleClickOpen2 = () => {
    console.log('opened');
    this.setState({ 
      open: true,
      isSessionCancelBtn: false 
    })

  };

  raiseReviewsForCancelSession = async () => {
    console.log(this.sessionId);
    let sessionRef = db.collection('Sessions').where("uniqueSessionID",
      "==",
      this.sessionId);
    let sessionDocs = await sessionRef.get();
    let sessionDoc = sessionDocs.docs[0].data();
    const mentorID = sessionDoc['mentorId'];
    const menteeID = sessionDoc['menteeId'];
    console.log(sessionDoc);

    let pendingReview = {
      'meetingDuration': sessionDoc['meetingDuration'],
      'meetingType' : sessionDoc['meetingType'],
      'nextMeetingTime' : sessionDoc['nextMeetingTime'],
      'perHourSessionPriceInDollars': sessionDoc['perHourSessionPriceInDollars'],
      'totalSessions': sessionDoc['totalSessions'],
      'uniqueSessionID': sessionDoc['uniqueSessionID'],
      'topic': sessionDoc['topic'],
      'mentorID': mentorID,
      'dateOfBooking': sessionDoc['dateOfBooking'],
      'menteeID': menteeID,
      'id' : ''
    };
    console.log(pendingReview);
    const mentorPendingReviewRef = db.collection('Users').doc(mentorID).collection('PendingReviews').doc();
    pendingReview['id'] = mentorPendingReviewRef.id;
    await mentorPendingReviewRef.set(sessionDoc);
    const menteePendingReviewRef = db.collection('Users').doc(menteeID).collection('PendingReviews').doc();
    pendingReview['id'] = menteePendingReviewRef.id;
    await menteePendingReviewRef.set(sessionDoc);

  };

  exitMeeting = async () => {
    let agora = new AgoraVideoCall();
    console.log('print meeting Ended');
    agora.handleExit();
  };

  cancelSession = async () => {
    let agora = new AgoraVideoCall();
    console.log('print sessioncancelled');


    let cancelledBy = firebase.auth().currentUser.uid;
    let isCancelledByClient;

    if (this.isExpert === true) {
      isCancelledByClient = false;
    } else {
      isCancelledByClient = true;
    }

    const sessionRef = await firebase.firestore().collection('Sessions').doc(this.sessionId);
    const messageRef = await firebase.firestore().collection('Messages').doc(this.sessionId);

    if (this.isFreeSession === "true") {

      console.log(`print cancelled by free session ${this.isFreeSession}`);
      await sessionRef.update({
        'status': 'Cancelled',
        'cancelledBy': cancelledBy,
        // "meetingRemaining": 0,
        "meetingId": '',
      });

      await messageRef.update({
        'status': 'Cancelled',
        'cancelledBy': cancelledBy,
        // "meetingRemaining": 0,
        "meetingId": '',
      });

    }
    else {
      let perMeetingFee = this.fee / this.totalMeetings;
      let perMinuteFee = perMeetingFee / this.meetingDuration;
      let elapsedMinute = this.state.elapsedMinutes;
      let cutoff = this.meetingDuration / 30;
      let elapsedCutoff = (elapsedMinute / 30) | 0;

      let refundAmountForRemainingMinutes = 0;

      if (elapsedCutoff + 1 !== cutoff) {
        let remainingCutoff = cutoff - (elapsedCutoff + 1);
        refundAmountForRemainingMinutes = remainingCutoff * perMinuteFee * 30;
      }

      var refundRequest = firebase.app().functions('asia-east2').httpsCallable('StripeRefundRequestLive');
      var result = await refundRequest({
        'elapsedMinute': this.state.elapsedMinutes,
        'refundAmountForRemainingMinutes': refundAmountForRemainingMinutes,
        'sessionId': this.sessionId,
        'cancelledBy': cancelledBy,
      });

      console.log(`result is ${result.data}`);

    }
    this.raiseReviewsForCancelSession();
    agora.handleExit();
  }

  render() {
    let buttonStyle = {
      backgroundColor: 'red',
      color: 'white',
      fontSize: '18px',
      padding: '10px 30px',
      borderRadius: '25px',
      margin: '0px 0px',
      cursor: 'pointer',
    }

    let buttonStylemobile = {
      backgroundColor: 'red',
      color: 'white',
      fontSize: '10px',
      padding: '10px 30px',
      borderRadius: '25px',
      margin: '0px 0px',
      cursor: 'pointer',
    }

    let buttonStyle2 = {
      backgroundColor: 'green',
      color: 'white',
      fontSize: '18px',
      padding: '10px 30px',
      borderRadius: '25px',
      margin: '0px 0px',
      cursor: 'pointer',
    }

    let buttonStyle2mobile = {
      backgroundColor: 'green',
      color: 'white',
      fontSize: '10px',
      padding: '10px 30px',
      borderRadius: '25px',
      margin: '0px 0px',
      cursor: 'pointer',
    }

    let buttonStyleNo = {
      backgroundColor: 'red',
      color: 'white',
      fontSize: '12px',
      padding: '10px 30px',
      borderRadius: '25px',
      margin: '0px 0px',
      cursor: 'pointer',
    }

    let buttonStyleYes = {
      backgroundColor: 'green',
      color: 'white',
      fontSize: '12px',
      padding: '10px 30px',
      borderRadius: '25px',
      margin: '0px 0px',
      cursor: 'pointer',
    }

    var androidLogo = require('../../assets/images/android-logo.png')
    var appleLogo = require('../../assets/images/apple-logo.png')
    var webLogo = require('../../assets/images/web-logo.png')



    return (
      <div className="wrapper meeting">
        <div className="ag-header">
          <div style={{ width: '20%' }} className="ag-header-lead">
            <img
              className="header-logo"
              src={require("../../assets/images/ag-logo.png")}
              alt=""
            />
            <span style={{ fontSize: 25, verticalAlign: 'bottom', }}><b>Session</b></span>
          </div>
          {window.innerWidth>650?
          <div style={{ width: '40%', height: 50 }} className="ag-header-msg">
            Meeting Outcome:&nbsp;<span style={{ color: 'grey' ,fontWeight: "bold"}} >{this.outcome}</span>
          </div>:<div></div>}
          <div style={{ width: '20%' }} className="ag-header-msg">
            Meeting ID:&nbsp;<span style={{ color: 'grey', fontWeight: "bold" }} id="room-name">{this.channel}</span>
          </div>
        </div>
        {window.innerWidth>650?<div></div>:
        <div className="ag-header">
        <div style={{height: 50 }} className="ag-header-msg">
            Meeting Outcome:&nbsp;<span style={{ color: 'grey' ,fontWeight: "bold"}} >{this.outcome}</span>
          </div></div>}
          {window.innerWidth>650?<div></div>:
        <div className="ag-header">
        <div style={{textAlign:"center" , width: "100%", height:0}} className="ag-header-msg">
            &nbsp;<span style={{ color: 'grey' , fontSize: 25}} >{this.state.countDown}</span>
          </div></div>}
        {window.innerWidth>650? 
         <div style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center"
              }} className='row'>
              <p style={{ color: 'grey', fontSize: 25,textAlign: "end" }}>{`${this.state.countDown}`}</p>
        </div> 
        : <div></div>}
        <div className="ag-main">
          <div className="ag-container">
            <AgoraVideoCall
              videoProfile={this.videoProfile}
              channel={this.channel}
              transcode={this.transcode}
              attendeeMode={this.attendeeMode}
              baseMode={this.baseMode}
              appId={this.appId}
              uid={this.uid}
              meetingDuration={this.meetingDuration}
              sessionId={this.sessionId}
              meetingNumber={this.totalMeetings - this.meetingRemaining + 1}
              isExpert={this.isExpert === "true"}
              userId={this.isExpert === "true" ? this.expertId : this.clientId}
              onTimerStarts={(timer, minutes) => this.setState({ countDown: timer, elapsedMinutes: minutes })}
            />
            {window.innerWidth>650? <br></br>:<div></div>}
            
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }} className='row'>
              {this.isExpert === 'true' ?<p style={{ color: '#b7b70f'}}>* Only the client can terminate the session.</p>:<p style={{ marginLeft: '16rem' }}>&nbsp;</p>}
              
              <Button style={window.innerWidth>650? buttonStyle2:buttonStyle2mobile}
                onClick={this.handleClickOpen2}
                variant='Contained'>
                End Meeting
              </Button>
              {this.isExpert === 'false'?
              <Button style={window.innerWidth>650? buttonStyle:buttonStylemobile}
                onClick={this.handleClickOpen}
                variant='Contained'>
                Cancel Session
              </Button>:
              <div style={window.innerWidth>650?{ marginLeft: '18.5rem' }:{}}></div>}
            </div>
            <br></br>
            <div className='row' style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",

            }}>
              <p style={{
                textAlign: "center",
                fontSize: 13,
                color: '#F85A40',
                fontWeight: "bold"
              }}>
                * For chatting or filling complaints, please use either of our apps conversations screen.                
              </p>
            </div>
            {window.innerWidth>650?<div></div>:<br></br>}
            <div className='row' style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",

            }}>
              <p style={{
                textAlign: "center",
                fontSize: 13,
                color: '#F85A40',
                fontWeight: "bold"
              }}>
                * All meetings could be recorded using external software for your own preview, as well as  troubleshooting with us.  Please note that Headstrt, does not record any meeting conversations.                
              </p>
            </div>
            <br></br>
            <div className='row' style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",

            }}>
              <p style={{
                textAlign: "center",
                fontSize: 13,
                color: 'grey',
                fontWeight: "bold"
              }}>
                For a better and faster experience.<br></br>
                Try our apps. <br></br>
                <a style={{
                  color: '#F85A40'
                }} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.headstrt.app", "_blank")} > <img src={androidLogo}/></a> or
                <a style={{
                  color: '#F85A40',
                }} onClick={() => window.open("https://headstrt.com", "_blank")} > <img src={webLogo}/> </a> or
                <a style={{
                  color: '#F85A40',
                }} onClick={() => window.open("https://apps.apple.com/in/app/headstrt/id1503116568", "_blank")} > <img src={appleLogo}/> </a>
              </p>
            </div>
            <div className='row' style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",

            }}>
              <p style={{
                textAlign: "center",
                fontSize: 13,
                color: '#b7b70f',
                fontWeight: "bold"
              }}>
                <br></br>         
              </p>
            </div>

          </div>
        </div>
        <div>

          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            scroll={this.scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title" style={{ textAlign: "center" }}>{this.state.isSessionCancelBtn===true? <b>Automated Refunds</b>:<b> Alert</b>}</DialogTitle>
            {this.state.isSessionCancelBtn===true?
            <DialogContent dividers={this.scroll === 'paper'}>
              <div className="center" >
                <img style={{ height: '300px', width: '600px', justifyContent: "center", AlignItems: 'center', alignSelf: 'center', display: 'flex' }} src={require('../../assets/images/delete.jpg')} alt="" />
              </div>
              <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                <b>No Refund: </b>  <br></br>
                <ol>
                  <li>Client A completes a session satisfactorily with Expert B</li>
                  <li>Client A completes a session with Expert B that has 4 meetings for a total of 200$, or each meeting being 50$. If the Client has completed all 4 meetings and did not report to us any complaints of proven inaccurate delivery or unacceptable behavior against the Expert, there would be no refund. In case of a conflict, the client will have to submit evidence of a breach of community guidelines or impersonation against a Expert, to get the money refunded from the last meeting, else it will amount to 0 refunds</li>
                </ol>
                <br></br>
                <b>Full refund: </b>  <br></br>
                <ol>
                  <li>Client cancels the session at least 3 days (or 72 hours) before the first meeting</li>
                  <li>Either the Client or the Expert reports each other before the first meeting</li>
                </ol>
                <br></br>
                <b>Partial refund:</b>  <br></br>
                <ol>
                  <li>Client cancels the session after the first meeting; The session fees + VAT/GST fees for the rest of the meetings will be returned on a prorated basis</li>
                  <li>Client cancels the session less than 3 days before the first meeting; The session fees + VAT/GST fees for the rest of the meetings will be returned on a prorated basis</li>
                  <li>Either the Client or the Expert reports each other before Session completes. In that case, the Client will be refunded the fees for the remaining meetings. They would also be returned the fee for the current meeting, rounded in the remaining 30-minute blocks. So in a 60-minute meeting, Client reported on the 31st minute, Client gets no refund. But if they cancel on the 29th minute or before then they would be refunded 30 minutes of session fees along with the proportionate GST/VAT/Sales tax</li>
                  <li>If meetings are not fully completed before the session end date</li>
                </ol>
                <br></br>
              </DialogContentText>
            </DialogContent> :
            <DialogContent dividers={this.scroll === 'paper'}>
              <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                <h1>
                  Do you want to end this meeting?
                </h1>
              </DialogContentText>
            </DialogContent>
            }
            {this.state.isSessionCancelBtn===true?
            <DialogActions>
              <Button onClick={this.cancelSession}
                style={{color: 'red'}}>
                Cancel Session
              </Button>
            </DialogActions>:
            <DialogActions style={{
                justifyContent: "center",
                alignItems: "center"
                }} className="center">
              <Button onClick={this.handleClose}
                style={buttonStyleYes}>
                No
              </Button>
              <p style={{ margin: '1rem' }}>&nbsp;</p>
              <Button onClick={this.exitMeeting}
                style={buttonStyleNo}>
                Yes
              </Button>
            </DialogActions>
            }
          </Dialog>
        </div>

      </div >
    );
  }
}


export default Meeting;
