import React from "react";
import { merge } from "lodash";
import AgoraRTC from "agora-rtc-sdk";
// import { Redirect } from "react-router";
import PropTypes from "prop-types";
import * as Cookies from "js-cookie";
import "./canvas.css";
import "../../assets/fonts/css/icons.css";
import * as firebase from "firebase/app";


const tile_canvas = {
  1: ["span 12/span 24"],
  2: ["span 12/span 12/13/25", "span 12/span 12/13/13"],
  3: ["span 6/span 12", "span 6/span 12", "span 6/span 12/7/19"],
  4: [
    "span 6/span 12",
    "span 6/span 12",
    "span 6/span 12",
    "span 6/span 12/7/13",
  ],
  5: [
    "span 3/span 4/13/9",
    "span 3/span 4/13/13",
    "span 3/span 4/13/17",
    "span 3/span 4/13/21",
    "span 9/span 16/10/21",
  ],
  6: [
    "span 3/span 4/13/7",
    "span 3/span 4/13/11",
    "span 3/span 4/13/15",
    "span 3/span 4/13/19",
    "span 3/span 4/13/23",
    "span 9/span 16/10/21",
  ],
  7: [
    "span 3/span 4/13/5",
    "span 3/span 4/13/9",
    "span 3/span 4/13/13",
    "span 3/span 4/13/17",
    "span 3/span 4/13/21",
    "span 3/span 4/13/25",
    "span 9/span 16/10/21",
  ],
};

/**
 * @prop appId uid
 * @prop transcode attendeeMode videoProfile channel baseMode
 */
class AgoraCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.client = {};
    this.localStream = {};
    this.shareClient = {};
    this.shareStream = {};
    this.userElapsedSeconds = 0;
    this.meetingElapsedSeconds = 0;
    this.i = 0;
    this.meetingDocId = '';
    this.meetingData = {};
    this.sessionRef = firebase.firestore().collection('Sessions');
    this.MeetingRef = this.sessionRef.doc(Cookies.get('sessionId')).collection('Meetings');
    this.meetingTime = Number.parseInt(Cookies.get('meetingTime'))
    this.state = {
      displayMode: "pip",
      streamList: [],
      readyState: false,
      finished: false,
      isUserJoined: false,

    };
  }
  static contextTypes = {
    router: PropTypes.object,
  };

  async getMeetingDetails() {
    console.log(`print meeting number is ${this.props.meetingNumber}`)
    const meetingDoc = await this.MeetingRef.where("meetingNumber", "==", this.props.meetingNumber).get();
    console.log(`print meeting doc is ${meetingDoc.docs.length}`)
    this.meetingDocId = meetingDoc.docs[0].id;
    this.meetingData = meetingDoc.docs[0].data();

  }

  async updateLocalUserTimestamp() {
    if (this.props.isExpert) {
      let expertJoinedTime = this.meetingData.expertJoinedTime;
      if (!expertJoinedTime) {
        await this.MeetingRef.doc(this.meetingDocId).update({
          'expertJoinedTime': firebase.firestore.Timestamp.now(),
        });
      }

    } else {
      let clientJoinedTime = this.meetingData.clientJoinedTime;
      if (!clientJoinedTime) {
        await this.MeetingRef.doc(this.meetingDocId).update({
          'clientJoinedTime': firebase.firestore.Timestamp.now(),
        });
      }
    }

  }

  async updateLocalUserSeconds() {
    let incrementedSeconds = firebase.firestore.FieldValue.increment(this.userElapsedSeconds);

    if (this.props.isExpert) {
      await this.MeetingRef.doc(this.meetingDocId).update({
        'expertOnlineDuration': incrementedSeconds,
      });

    } else {
      await this.MeetingRef.doc(this.meetingDocId).update({
        'clientOnlineDuration': incrementedSeconds,
      });

    }

  }

  async updateMeetingSeconds() {
    let incrementedSeconds = firebase.firestore.FieldValue.increment(this.meetingElapsedSeconds);

    await this.MeetingRef.doc(this.meetingDocId).update({
      'totalMeetingSeconds': incrementedSeconds,
    });
  }

  async updateMeetingTimestamp() {
    let meetingStartedTime = this.meetingData.meetingStartedTime;

    if (!meetingStartedTime) {
      await this.MeetingRef.doc(this.meetingDocId).update({
        'meetingStartedTime': firebase.firestore.Timestamp.now(),
      });
    }
  }

  componentWillMount() {
    let $ = this.props;
    // init AgoraRTC local client

    this.client = AgoraRTC.createClient({ mode: $.transcode });
    this.client.init($.appId, () => {
      console.log("AgoraRTC client initialized");
      this.subscribeStreamEvents();
      console.log("Call timer initialsed");

      this.client.join($.appId, $.channel, $.uid, (uid) => {
        console.log("User " + uid + " join channel successfully");
        console.log("At " + new Date().toLocaleTimeString());
        // create local stream
        // It is not recommended to setState in function addStream
        this.localStream = this.streamInit(uid, $.attendeeMode, $.videoProfile);
        this.localStream.init(
          () => {
            if ($.attendeeMode !== "audience") {
              this.addStream(this.localStream, true);
              this.client.publish(this.localStream, (err) => {
                console.log("Publish local stream error: " + err);
              });
            }
            this.setState({ readyState: true });
            this.updateLocalUserTimestamp();
          },
          (err) => {
            console.log("getUserMedia failed", err);
            this.setState({ readyState: true });
          }
        );
        this.callTimer();
      });
    });
  }

  componentDidMount() {
    // add listener to control btn group
    this.getMeetingDetails();
    let canvas = document.querySelector("#ag-canvas");
    let btnGroup = document.querySelector(".ag-btn-group");
    canvas.addEventListener("mousemove", () => {
      if (global._toolbarToggle) {
        clearTimeout(global._toolbarToggle);
      }
      btnGroup.classList.add("active");
      global._toolbarToggle = setTimeout(function () {
        btnGroup.classList.remove("active");
      }, 2000);
    });
  }

  // componentWillUnmount () {
  //     // remove listener
  //     let canvas = document.querySelector('#ag-canvas')
  //     canvas.removeEventListener('mousemove')
  // }

  componentDidUpdate() {
    // rerendering
    let canvas = document.querySelector("#ag-canvas");
    // pip mode (can only use when less than 4 people in channel)
    if (this.state.displayMode === "pip") {
      let no = this.state.streamList.length;
      if (no > 4) {
        this.setState({ displayMode: "tile" });
        return;
      }
      this.state.streamList.map((item, index) => {
        let id = item.getId();
        let dom = document.querySelector("#ag-item-" + id);
        if (!dom) {
          dom = document.createElement("section");
          dom.setAttribute("id", "ag-item-" + id);
          dom.setAttribute("class", "ag-item");
          canvas.appendChild(dom);
          item.play("ag-item-" + id);
        }
        if (index === no - 1) {
          dom.setAttribute("style", `grid-area: span 12/span 24/13/25`);
        } else {
          dom.setAttribute(
            "style",
            `grid-area: span 3/span 4/${4 + 3 * index}/25;
                    z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
          );
        }

        item.player.resize && item.player.resize();
      });
    }
    // tile mode
    else if (this.state.displayMode === "tile") {
      let no = this.state.streamList.length;
      this.state.streamList.map((item, index) => {
        let id = item.getId();
        let dom = document.querySelector("#ag-item-" + id);
        if (!dom) {
          dom = document.createElement("section");
          dom.setAttribute("id", "ag-item-" + id);
          dom.setAttribute("class", "ag-item");
          canvas.appendChild(dom);
          item.play("ag-item-" + id);
        }
        dom.setAttribute("style", `grid-area: ${tile_canvas[no][index]}`);
        item.player.resize && item.player.resize();
      });
    }
    // screen share mode (tbd)
    else if (this.state.displayMode === "share") {
    }
  }

  componentWillUnmount() {
    this.client && this.client.unpublish(this.localStream);
    this.updateLocalUserSeconds();
    this.localStream && this.localStream.close();
    this.client &&
      this.client.leave(
        () => {
          console.log("Client succeed to leave.");
        },
        () => {
          console.log("Client failed to leave.");
        }
      );
  }

  streamInit = (uid, attendeeMode, videoProfile, config) => {
    let defaultConfig = {
      streamID: uid,
      audio: true,
      video: true,
      screen: false,
    };

    switch (attendeeMode) {
      case "screenshare":
        defaultConfig.video = false;
        defaultConfig.screen = true;
      case "audio-only":
        defaultConfig.video = false;
        defaultConfig.screen = window.innerWidth>650?true:false;
        break;
      case "audience":
        defaultConfig.video = false;
        defaultConfig.audio = false;
        break;
      default:
      case "video":
        break;
    }

    let stream = AgoraRTC.createStream(merge(defaultConfig, config));
    stream.setVideoProfile(videoProfile);
    return stream;
  };

  callTimer = (e) => {
    let localObject = this;
    let prop = this.props;
    var countDownDate = new Date();
    console.log(`print meetign time ${new Date(Date.now()).getMinutes()}-${new Date(this.meetingTime).getMinutes()}`);
    let meetingDuration = parseInt(Cookies.get("meetingDuration"));
    let leftDuration = new Date(Date.now()).getMinutes() - new Date(this.meetingTime).getMinutes();


    countDownDate.setMinutes(countDownDate.getMinutes() + (meetingDuration - leftDuration));
    var newTime = countDownDate.getTime() + (countDownDate.getSeconds() + 1);
    // Update the count down every 1 second
    console.log("Times are " + newTime + " " + new Date().getTime());



    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();
      localObject.i++;
      localObject.userElapsedSeconds++;
      localObject.meetingElapsedSeconds++;


      // Find the distance between now and the count down date
      var distance = newTime - now;


      var elapsedMinutes = localObject.i;

      // Time calculations for days, hours, minutes and seconds
      // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      // console.log(hours + "h " + minutes + "m " + seconds + "s ");

      let roundedHours = hours < 10 ? '0' + `${hours}` : `${hours}`;
      let roundedMins = minutes < 10 ? '0' + `${minutes}` : `${minutes}`;
      let roundedSecs = seconds < 10 ? '0' + `${seconds}` : `${seconds}`;

      let elapsedTime = `${roundedHours}:${roundedMins}:${roundedSecs}`;

      prop.onTimerStarts(elapsedTime, elapsedMinutes);

      // If the count down is over, write some text
      if (distance < 0) {
        alert("Time up");
        clearInterval(x);
        try {
          this.client && this.client.unpublish(this.localStream);
          this.updateLocalUserSeconds();
          this.localStream && this.localStream.close();
          this.client &&
            this.client.leave(
              () => {
                console.log("Client succeed to leave.");
              },
              () => {
                console.log("Client failed to leave.");
              }
            );
        }
        finally {
          this.client = null;
          this.localStream = null;
          // redirect to index
          window.location.href = "/";
        }
      }
    }, 1000);
    console.log();
  };

  subscribeStreamEvents = () => {
    let rt = this;


    rt.client.on("stream-added", function (evt) {

      let stream = evt.stream;
      console.log("New stream added: " + stream.getId());
      console.log("At " + new Date().toLocaleTimeString());
      console.log("Subscribe ", stream);
      rt.i = 0;
      rt.updateMeetingTimestamp();
      rt.meetingElapsedSeconds = 0;

      rt.client.subscribe(stream, function (err) {
        console.log("Subscribe stream failed", err);
      });
    });

    rt.client.on("peer-leave", function (evt) {
      console.log("Peer has left: " + evt.uid);
      console.log(new Date().toLocaleTimeString());
      console.log(evt);
      rt.removeStream(evt.uid);
      rt.i = 0;
      rt.updateMeetingSeconds();
    });

    rt.client.on("stream-subscribed", function (evt) {
      let stream = evt.stream;
      console.log("Got stream-subscribed event");
      console.log(new Date().toLocaleTimeString());
      console.log("Subscribe remote stream successfully: " + stream.getId());
      console.log(evt);
      rt.addStream(stream);
    });

    rt.client.on("stream-removed", function (evt) {
      let stream = evt.stream;
      console.log("Stream removed: " + stream.getId());
      console.log(new Date().toLocaleTimeString());
      console.log(evt);
      rt.removeStream(stream.getId());
    });
  };

  removeStream = (uid) => {
    this.state.streamList.map((item, index) => {
      if (item.getId() === uid) {
        item.close();
        let element = document.querySelector("#ag-item-" + uid);
        if (element) {
          element.parentNode.removeChild(element);
        }
        let tempList = [...this.state.streamList];
        tempList.splice(index, 1);
        this.setState({
          streamList: tempList,
        });
      }
    });
  };

  addStream = (stream, push = true) => {
    let repeatition = this.state.streamList.some((item) => {
      return item.getId() === stream.getId();
    });
    if (repeatition) {
      return;
    }
    if (push) {
      this.setState({
        streamList: this.state.streamList.concat([stream]),
      });
    } else {
      this.setState({
        streamList: [stream].concat(this.state.streamList),
      });
    }
  };

  handleCamera = (e) => {
    e.currentTarget.classList.toggle("off");
    this.localStream.isVideoOn()
      ? this.localStream.disableVideo()
      : this.localStream.enableVideo();
  };

  handleMic = (e) => {
    e.currentTarget.classList.toggle("off");
    this.localStream.isAudioOn()
      ? this.localStream.disableAudio()
      : this.localStream.enableAudio();
  };

  handleScreenShare = (e) => {
    e.currentTarget.classList.toggle("off");
    this.localStream.isVideoOn()
      ? this.localStream.disableVideo()
      : this.localStream.enableVideo();
  };

  switchDisplay = (e) => {
    if (
      e.currentTarget.classList.contains("disabled") ||
      this.state.streamList.length <= 1
    ) {
      return;
    }
    if (this.state.displayMode === "pip") {
      this.setState({ displayMode: "tile" });
    } else if (this.state.displayMode === "tile") {
      this.setState({ displayMode: "pip" });
    } else if (this.state.displayMode === "share") {
      // do nothing or alert, tbd
    } else {
      console.error("Display Mode can only be tile/pip/share");
    }
  };

  hideRemote = (e) => {
    if (
      e.currentTarget.classList.contains("disabled") ||
      this.state.streamList.length <= 1
    ) {
      return;
    }
    let list;
    let id = this.state.streamList[this.state.streamList.length - 1].getId();
    list = Array.from(
      document.querySelectorAll(`.ag-item:not(#ag-item-${id})`)
    );
    list.map((item) => {
      if (item.style.display !== "none") {
        item.style.display = "none";
      } else {
        item.style.display = "block";
      }
    });
  };

  handleExit = () => {
    // if (e.currentTarget.classList.contains("disabled")) {
    //   return;
    // }


    try {
      this.client && this.client.unpublish(this.localStream);
      this.updateLocalUserSeconds();

      this.localStream && this.localStream.close();
      this.client &&
        this.client.leave(
          () => {
            console.log("Client succeed to leave.");
          },
          () => {
            console.log("Client failed to leave.");
          }
        );
    } finally {
      this.setState({ readyState: false, finished: true });
      this.client = null;
      this.localStream = null;
      // redirect to index
      window.location.href = "/";
      //this.history.push("/");
    }

  };

  render() {
    const style = {
      display: "grid",
      gridGap: "10px",
      alignItems: "center",
      justifyItems: "center",
      gridTemplateRows: "repeat(12, auto)",
      gridTemplateColumns: "repeat(24, auto)",
    };
    const videoControlBtn =
      this.props.attendeeMode === "video" ? (
        <span
          onClick={this.handleCamera}
          className="ag-btn videoControlBtn"
          title="Enable/Disable Video"
        >
          <i className="ag-icon ag-icon-camera"></i>
          <i className="ag-icon ag-icon-camera-off"></i>
        </span>
      ) : (
        ""
      );

    const audioControlBtn =
      this.props.attendeeMode !== "audience" ? (
        <span
          onClick={this.handleMic}
          className="ag-btn audioControlBtn"
          title="Enable/Disable Audio"
        >
          <i className="ag-icon ag-icon-mic"></i>
          <i className="ag-icon ag-icon-mic-off"></i>
        </span>
      ) : (
        ""
      );

    const screenShareControlBtn =
      this.props.attendeeMode !== "audience" ? (
        <span
          onClick={this.handleScreenShare}
          className="ag-btn screenShareControlBtn"
          title="Hide/Unhide ScreenShare. If enabled, Tap the on-screen Stop Sharing button to stop screen share."
        >
          <i className="ag-icon ag-icon-screen-share"></i>
          <i className="ag-icon ag-icon-screen-share-off"></i>
        </span>
      ) : (
        ""
      );

    const switchDisplayBtn = (
      <span
        onClick={this.switchDisplay}
        className={
          this.state.streamList.length > 4
            ? "ag-btn displayModeBtn disabled"
            : "ag-btn displayModeBtn"
        }
        title="Switch Display Mode"
      >
        <i className="ag-icon ag-icon-switch-display"></i>
      </span>
    );
    const hideRemoteBtn = (
      <span
        className={
          this.state.streamList.length > 4 || this.state.displayMode !== "pip"
            ? "ag-btn disableRemoteBtn disabled"
            : "ag-btn disableRemoteBtn"
        }
        onClick={this.hideRemote}
        title="Hide Remote Stream"
      >
        <i className="ag-icon ag-icon-remove-pip"></i>
      </span>
    );
    const exitBtn = (
      <span
        onClick={this.props.isExpert ? () => { } : this.handleExit}
        className={
          this.state.readyState ? "ag-btn exitBtn" : "ag-btn exitBtn disabled"
        }
        title="Exit"
      >
        <i className="ag-icon ag-icon-leave"></i>
      </span>
    );

    return (
      <div id="ag-canvas" style={style}>
        <div className="ag-btn-group">
          {/* {exitBtn} */}
          {videoControlBtn}
          {/* <span className="ag-btn shareScreenBtn" title="Share Screen">
                        <i className="ag-icon ag-icon-screen-share"></i>
                    </span> */}
          {switchDisplayBtn}
          {audioControlBtn}
          {screenShareControlBtn}
          {hideRemoteBtn}
        </div>
      </div>
    );
  }
}

export default AgoraCanvas;