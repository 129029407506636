import React from "react";
import * as Cookies from "js-cookie";
import * as firebase from 'firebase'
import "../../assets/fonts/css/icons.css";
import Validator from "../../utils/Validator";
// import { RESOLUTION_ARR } from "../../utils/Settings";
import "./index.css";
import app from "./../../utils/firebase";
const db = app.firestore();

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      joinBtn: false,
      channel: "",
      baseMode: "avc",
      transcode: "interop",
      attendeeMode: "video",
      videoProfile: "480p_4",
      userEmail: app.auth().currentUser.email,
      userId: app.auth().currentUser.uid,
    };
  }

  componentDidMount() {
    window.addEventListener("keypress", (e) => {
      e.keyCode === 13 && this.handleJoin();
    });
  }

  /**
   *
   * @param {String} val 0-9 a-z A-Z _ only
   * @param {Boolean} state
   */
  handleChannel = (val, state) => {
    this.setState({
      channel: val,
      joinBtn: state,
    });
  };

  /*
  validateMeeting = (channel,email) => {
    this.setState({
      filter: null
    })
    var mentor;
    var mentee;
    var mentorEmail;
    var menteeEmail;
    var eventID = "";
    db.collection("Sessions")
      .get()
      .then(querySnapshot => {
       querySnapshot.docs.forEach((doc) => {
        if(doc.requestInfo !== undefined){
        eventID = (doc.requestInfo.eventID).substring(0,8);
      }
        if (eventID === "" || eventID === null) {
          eventID = "";
        }
        if (channel === eventID) {
        
          mentor = doc.requestInfo.mentorID;
          mentee = doc.requestInfo.menteeID;
        }
        else {
          mentor = "";
          mentee = "";
        }
      }
    );
      console.log(eventID); 
  });
    
    if((mentor !== "" || mentor !== null) && (mentee !== ""|| mentee !== null)){
      db.collection("Users")
      .where("databaseID", "==", "8zM0PHPRFpg0S2gkJVRrOozHQBo2")
      .get()
      .then(doc => {
        mentorEmail = doc.email; 
        console.log(mentorEmail);
      });
      db.collection("Users")
      .where("databaseID", "==", "YAJp23lpjHcQLCuu2rcyx4k2ImW2")
      .get()
      .then(doc => {
          menteeEmail= doc.email;
          console.log(menteeEmail);
      });
      if (email === mentorEmail){
        this.setState({
          filter: 1
        })
      }
      else if(email === menteeEmail){
        this.setState({
          filter: 1
        })
      }
      else{
        this.setState({
          filter: 0
        })
      }
    }
    else{
      return alert("No meeting found!");
    }
  }

*/

  async updateParticipants(particiants, docID) {
    if (particiants === undefined) particiants = [];
    particiants.push(this.state.userEmail);
    const TeamRequestsRef = db.collection("Team_Requests").doc(docID);
    await TeamRequestsRef.update({
      currentParticipants: particiants,
    });
  }

  async getSessionDetails() {
    const sessionDoc = await db.collection('Sessions').where("meetingId",
      "==",
      this.state.channel).get();
    if (sessionDoc.empty) {
      return false;
    } else {
      const sessionData = sessionDoc.docs[0].data();
      const expertId = sessionData['participants'][0];
      const clientId = sessionData['participants'][1];
      const sessionId = sessionData['uniqueSessionID'];
      const fee = sessionData['totalMentoringFees'];
      const isFreeSession = fee === 0;
      const totalMeetings = sessionData['requestInfo']['numberOfMeetings'];
      const meetingRemaining = sessionData['meetingRemaining'];
      const meetingDuration = sessionData['meetingDuration'];
      const txnId = sessionData['transactionID'];
      const meetingTime = sessionData['nextMeetingTime'].toMillis();
      const sessionStatus = sessionData['status'];
      const outcome = sessionData['topic'];

      const isExpert = expertId === this.state.userId;
      Cookies.set('isExpert', `${isExpert}`);
      Cookies.set('expertId', expertId);
      Cookies.set('clientId', clientId)
      Cookies.set('txnId', txnId);
      Cookies.set('sessionId', sessionId);
      Cookies.set('fee', fee);
      Cookies.set('isFreeSession', `${isFreeSession}`);
      Cookies.set('totalMeetings', `${totalMeetings}`)
      Cookies.set('meetingRemaining', `${meetingRemaining}`)
      Cookies.set('meetingDuration', `${meetingDuration}`)
      Cookies.set('meetingTime', `${meetingTime}`)
      Cookies.set('outcome', outcome);

      console.log(`print sessionId Is ${Cookies.get('meetingTime')}`)

      // let dateNow = new Date(Date.now());

      let isSessionEnded = sessionStatus === 'Session Ended';

      let isJoiningBefore = new Date(meetingTime).valueOf() > new Date(Date.now()).valueOf();
      console.log(`print time ${new Date(meetingTime).valueOf() - new Date(Date.now() - (1000 * 60 * 60 * 1)).valueOf()}`);
      console.log(`print now ${new Date(Date.now()).valueOf()}`);

      if (isFreeSession) {
        this.setState({ attendeeMode: "audio-only" })

      }

      return {
        isValid: true,
        isJoiningBefore: isJoiningBefore,
        isSessionEnded: isSessionEnded,
      };
    }
  }

  async getData() {
    //hzsdqwq7QznryrGaJMGM
    const TeamRequestsRef = db.collection("Team_Requests");
    //const doc = await citiesRef.get();
    const snapshot = await TeamRequestsRef.where(
      "status",
      "==",
      "accepted"
    ).get();
    if (snapshot.empty) {
      console.log("No matching documents.");
      return;
    }

    for (var i = 0; i < snapshot.docs.length; i++) {
      //console.log(doc.id, '=>', doc.data());
      // var docData = snapshot.docs[i].data();
      var eventID = snapshot.docs[i].get("sendRequest")["eventID"];
      var participantList;
      if (eventID !== undefined) {
        if (eventID.substr(0, 8) === this.state.channel) {
          console.log(
            snapshot.docs[i].get("sendRequest")["eventID"].substr(0, 8)
          );
          /* 
    this.setState({
      userEmail: app.auth().currentUser.email,
      meetingDuration: snapshot.docs[i].get('sendRequest')['meetingDuration'],
      maxParticipants: snapshot.docs[i].get('messageRequest')['noOfMembers'],
      startTime: new Date().getTime(),
      currentParticipants: snapshot.docs[i].get('currentParticipants'),
      docID: snapshot.docs[i].get('docID')
    });
    */
          var userEmail = app.auth().currentUser.email;
          var meetingDuration = snapshot.docs[i].get("sendRequest")[
            "meetingDuration"
          ];
          var maxParticipants = snapshot.docs[i].get("messageRequest")[
            "noOfMembers"
          ];
          var startTime = new Date().getTime();
          var currentParticipants = snapshot.docs[i].get("currentParticipants");
          var docID = snapshot.docs[i].get("docID");
          this.setState({
            meetingDuration: meetingDuration,
            startTime: startTime,
          });

          console.log("State set");
          //console.log((this.state.currentParticipants).length ?? 0);
          console.log(snapshot.docs[i].get("messageRequest")["noOfMembers"]);
          console.log(userEmail);
          console.log(maxParticipants);
          var noOParticipants;
          if (currentParticipants === undefined) {
            noOParticipants = 0;
          } else {
            noOParticipants = currentParticipants.length;
          }

          console.log("Participants passed");
          if (currentParticipants === undefined) {
            participantList = [];
          } else {
            participantList = currentParticipants;
          }
          console.log(participantList);
          var isThere = participantList.includes(userEmail);
          if (isThere) {
            console.log("User already found");
            return true;
          } else {
            if (noOParticipants < maxParticipants) {
              console.log("Updated participant");
              this.updateParticipants(currentParticipants, docID);
              return true;
            } else {
              console.log("Length error");
              return false;
            }
          }
        }
      }
      return false;
    }
  }

  handleJoin = async () => {
    if (!this.state.joinBtn) {
      return;
    }
    this.getSessionDetails().then((data) => {

      if (data) {

        // console.log(`data is available`);

        if (data.isJoiningBefore) {
          return alert("You are joining before the schedule!");
        } else if (data.isSessionEnded) {
          return alert("Session Expired!");


        }

        else {

          console.log("If Success");
          console.log(app.auth().currentUser.email);
          console.log(this.state);
          Cookies.set("channel", this.state.channel);
          Cookies.set("baseMode", this.state.baseMode);
          Cookies.set("transcode", this.state.transcode);
          Cookies.set("attendeeMode", this.state.attendeeMode);
          Cookies.set("videoProfile", this.state.videoProfile);
          Cookies.set("videoProfile", this.state.videoProfile);
          console.log(this.state.meetingDuration);
          Cookies.set("userEmail",);

          //     //window.location.hash = "meeting" //}
          this.props.history.push({
            pathname: '/meeting',
            state: { 'data': 'data_you_need_to_pass' }
          });
        }
      } else {
        console.log("If Failed");
        return alert("Invalid Meeting ID or Max Participants!");
      }
    });
  };

  render() {
    let textStyle = {
      fontSize: 25,
      color: '#F85A40'
    }
    let footerStyle = {
      backgroundColor: "#F85A40",
      borderTop: "1px solid #E7E7E7",
      textAlign: "center",
      padding: "20px",
      position: "bottom",
      position: "relative",
      clear: "both",
      left: "0",
      bottom: "0",
      height: "60px",
      width: "100%",
      color: "#FFFFFF"
    }

    let linkStyle = {
      color: '#FFFFFF'

    }
    
    var androidLogo = require('../../assets/images/android-logo.png')
    var appleLogo = require('../../assets/images/apple-logo.png')
    var webLogo = require('../../assets/images/web-logo.png')

    return (
      <div style={{
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
        <div className="wrapper index">
          {/* <div className="ag-header"></div> */}
          <div className="ag-main">
            <img width={350} style={{ marginTop: '20px' }} src={require('../../assets/images/global.png')} alt="" />

            <section className="login-wrapper">
              <div className="login-header">
                <p style={textStyle} className="login-subtitle"><b> Headstrt Sessions </b></p>

              </div>
              <div className="login-body">
                <div className="columns">
                  <div className="column is-12">
                    <InputChannel
                      onChange={this.handleChannel}
                      placeholder="Meeting ID"
                    ></InputChannel>
                  </div>
                </div>

                {/* <div className="columns">
                  <div className="column is-7">
                    <BaseOptions
                      onChange={(val) => this.setState({ baseMode: val })}
                    ></BaseOptions>
                  </div>
                  <div className="column is-5">
                    <AdvancedOptions
                      onRadioChange={(val) => this.setState({ transcode: val })}
                      onSelectChange={(val) =>
                        this.setState({ videoProfile: val })
                      }
                    ></AdvancedOptions>
                  </div>
                </div> */}
                {/* <div className="columns">
                  <div className="column">
                    <div id="attendeeMode" className="control">
                      <label className="radio">
                        <input
                          onChange={(e) => {
                            console.log('e value is ' + e.target.value);
                            this.setState({ attendeeMode: e.target.value })
                          }
                          }
                          value="video"
                          type="radio"
                          name="attendee"
                          defaultChecked
                        />
                        <span className="radio-btn"></span>
                        <span className="radio-img video"></span>
                        <span className="radio-msg">
                          Video Call : join with video call
                      </span>
                      </label>
                      <br />
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="login-footer">
                <a
                  id="joinBtn"
                  onClick={this.handleJoin}
                  disabled={!this.state.joinBtn}
                  className="ag-rounded button is-info" >
                  Join
                </a>
              </div>
              <div className="sign-out">
                <button
                  onClick={() => app.auth().signOut()}
                  className="ag-rounded button is-info sign-out"
                >
                  Sign out
              </button>
              </div>
            </section>
          </div>



        </div>

        <p> </p>


        <div className='row' style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",

        }}>
          <p style={{
            textAlign: "center",
            fontSize: 13,
            color: 'grey',
            fontWeight: "bold"
          }}>
            Use our any of our apps for chatting.<br></br>Get them at,<br></br>
            <a style={{
              color: '#F85A40'
            }} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.headstrt.app", "_blank")} > <img style={{ color: 'red'}} src={androidLogo}/></a> or
            <a style={{
              color: '#F85A40',
            }} onClick={() => window.open("https://headstrt.com", "_blank")} > <img src={webLogo}/> </a> or
            <a style={{
              color: '#F85A40',
            }} onClick={() => window.open("https://apps.apple.com/in/app/headstrt/id1503116568", "_blank")} > <img src={appleLogo}/> </a>
          </p>
        </div>  


        <footer style={footerStyle} >
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }} className='row'>
            <p> </p>
            <a style={linkStyle} onClick={() => window.open("https://www.about.headstrt.com/", "_blank")} >Home </a>
            <a style={linkStyle} onClick={() => window.open("https://www.about.headstrt.com/safety-guidelines", "_blank")} >Safety Guidelines</a>
            <a style={linkStyle} onClick={() => window.open("https://www.about.headstrt.com/community-guidelines", "_blank")} >Community Guidelines</a>
            <a style={linkStyle} onClick={() => window.open("https://www.about.headstrt.com/expert-guidelines", "_blank")} >Experts Guidelines</a>
            <p> </p>
          </div>
        </footer>
      </div>
    );
  }
}

class InputChannel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: "",
      state: "",
    };
  }

  validate = (val) => {
    this.setState({
      state: "",
      errorMsg: "",
    });
    if (Validator.isNonEmpty(val.trim())) {
      this.setState({
        errorMsg: "Cannot be empty!",
        state: "is-danger",
      });
      return false;
    } else if (Validator.minLength(val.trim(), 8)) {
      this.setState({
        errorMsg: "No shorter than 8!",
        state: "is-danger",
      });
      return false;
    } else if (Validator.maxLength(val.trim(), 8)) {
      this.setState({
        errorMsg: "Cannot be more than 8!",
        state: "is-danger",
      });
      return false;
    } else if (Validator.validChar(val.trim())) {
      this.setState({
        state: "is-danger",
        errorMsg: "Please enter a valid Meeting ID!",
      });
      return false;
    } else {
      this.setState({
        state: "is-success",
      });
      return true;
    }
  };

  handleChange = (e) => {
    let state = this.validate(e.target.value);
    this.props.onChange(e.target.value, state);
  };

  render() {
    let validateIcon = "";
    switch (this.state.state) {
      default:
      case "":
        validateIcon = "";
        break;
      case "is-success":
        validateIcon = <i className="ag-icon ag-icon-valid"></i>;
        break;
      case "is-danger":
        validateIcon = <i className="ag-icon ag-icon-invalid"></i>;
        break;
    }

    return (
      <div className="channel-wrapper control has-icons-left">
        <input
          onInput={this.handleChange}
          id="channel"
          className={"ag-rounded input " + this.state.state}
          type="text"
          placeholder={this.props.placeholder}
        />
        {/*
        <span className="icon is-small is-left">
          <img src={require('../../assets/images/ag-login.png')} alt="" />
        </span>
        */}
        <span className="validate-icon">{validateIcon}</span>
        <div className="validate-msg">{this.state.errorMsg}</div>
      </div>
    );
  }
} /*}
        <span className="validate-icon">
          {validateIcon}
        </span>
        <div className="validate-msg">
          {this.state.errorMsg}
        </div>
    </div>
    )
  }
}

*/

/*
class EmailChannel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMsg: '',
      state: ''
    }
  }

  validateEmail = (val) => {
    this.setState({
      state: '',
      errorMsg: ''
    })
    if (Validator.isNonEmpty(val.trim())) {
      this.setState({
        errorMsg: 'Cannot be empty!',
        state: 'is-danger'
      })
      return false
    }
    else if (Validator.minLength(val.trim(), 1)) {
      this.setState({
        errorMsg: 'No shorter than 1!',
        state: 'is-danger'
      })
      return false
    }
    else if (Validator.validEmail(val.trim())) {
      this.setState({
        state: 'is-danger',
        errorMsg: 'Please enter a valid Email ID!'
      })
      return false
    }
    else {
      this.setState({
        state: 'is-success'
      })
      return true
    }
  }

  handleChange = (e) => {
    let state = this.validateEmail(e.target.value)
    this.props.onChange(e.target.value, state)
  }

  handleEmail = (e) => {
    let state = this.validate(e.target.value)
    this.props.onChange(e.target.value, state)
  }

  render() {
    let validateIcon = ''
    switch (this.state.state) {
      default:
      case '':
        validateIcon = ''; break;
      case 'is-success':
        validateIcon = (<i className="ag-icon ag-icon-valid"></i>); break;
      case 'is-danger':
        validateIcon = (<i className="ag-icon ag-icon-invalid"></i>); break;
    }

    return (
      <div className="channel-wrapper control has-icons-left">
        <input onInput={this.handleChange}
          id="channel"
          className={'ag-rounded input ' + this.state.state}
          type="text"
          placeholder={this.props.placeholder} />
        {/*
        <span className="icon is-small is-left">
          <img src={require('../../assets/images/ag-login.png')} alt="" />
        </span>
        */
// class BaseOptions extends React.Component {
//   constructor(props) {
//     super(props);
//     this._options = [
//       {
//         label: "Video Call",
//         value: "avc",
//         content: "One to one call with Clients/Experts",
//       },
//       // {
//       //   label: 'Agora Live',
//       //   value: 'al',
//       //   content: 'Enabling real-time interactions between the host and the audience',
//       // },
//     ];
//     this.state = {
//       active: false,
//       message: "Video Call",
//     };
//   }

//   handleSelect = (item) => {
//     let msg = item.label;
//     let val = item.value;
//     this.setState({
//       message: msg,
//       active: false,
//     });
//     this.props.onChange(val);
//   };

//   render() {
//     const options = this._options.map((item, index) => {
//       return (
//         <div
//           className="dropdown-item"
//           key={index}
//           onClick={(e) => this.handleSelect(item, e)}
//         >
//           <p>{item.label}</p>
//           <hr />
//           <p>{item.content}</p>
//         </div>
//       );
//     });

//     return (
//       <div className={this.state.active ? "dropdown is-active" : "dropdown"}>
//         <div
//           className="dropdown-trigger"
//           onClick={() => this.setState({ active: !this.state.active })}
//         >
//           <a
//             id="baseMode"
//             className="ag-rounded button"
//             aria-haspopup="true"
//             aria-controls="baseModeOptions"
//           >
//             <span id="baseOptionLabel">{this.state.message}</span>
//             <span className="icon is-small">
//               <i className="ag-icon ag-icon-arrow-down" aria-hidden="true"></i>
//             </span>
//           </a>
//         </div>
//         <div className="dropdown-menu" id="baseModeOptions" role="menu">
//           <div className="dropdown-content">{options}</div>
//         </div>
//       </div>
//     );
//   }
// }

// class AdvancedOptions extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       active: false,
//     };
//   }

//   handleRadio = (e) => {
//     this.props.onRadioChange(e.target.value);
//   };

//   handleSelect = (e) => {
//     this.props.onSelectChange(e.target.value);
//   };

//   render() {
//     const options = Object.entries(RESOLUTION_ARR).map((item, index) => {
//       return (
//         <option key={index} value={item[0].split(",")[0]}>
//           {item[1][0]}x {item[1][1]}, {item[1][2]}fps, {item[1][3]}kbps
//         </option>
//       );
//     });
//     return (
//       <div className={this.state.active ? "dropdown is-active" : "dropdown"}>
//         <div
//           className="dropdown-trigger"
//           onClick={() => this.setState({ active: !this.state.active })}
//         >
//           <a
//             id="advancedProfile"
//             className="ag-rounded button"
//             aria-haspopup="true"
//             aria-controls="advancedOptions"
//           >
//             <span>Advanced</span>
//           </a>
//         </div>
//         <div className="dropdown-menu" id="advancedOptions" role="menu">
//           <div className="dropdown-content">
//             <div className="dropdown-item">
//               <div className="control">
//                 <label className="radio">
//                   <input
//                     value=""
//                     type="radio"
//                     name="transcode"
//                     onChange={this.handleRadio}
//                   />
//                   <span>VP8-only</span>
//                 </label>
//                 <label className="radio">
//                   <input
//                     value="interop"
//                     type="radio"
//                     defaultChecked
//                     onChange={this.handleRadio}
//                     name="transcode"
//                   />
//                   <span>VP8 &amp; H264</span>
//                 </label>
//                 <label className="radio">
//                   <input
//                     value="h264_interop"
//                     type="radio"
//                     onChange={this.handleRadio}
//                     name="transcode"
//                   />
//                   <span>H264-only</span>
//                 </label>
//               </div>
//             </div>
//             <div className="dropdown-item">
//               <div className="select is-rounded">
//                 <select
//                   onChange={this.handleSelect}
//                   defaultValue="480p_4"
//                   id="videoProfile"
//                   className="ag-rounded is-clipped"
//                 >
//                   {options}
//                 </select>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

export default Index;
