import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import app from "./../../utils/firebase";
import { AuthContext } from "./../Auth";
import './login.css'

const Login = ({ history }) => {
  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  let textStyle = {
    fontSize: 25,
    color: '#F85A40',
    textAlign: 'center',
    margin: '30px 0',
  }

  let footerStyle = {
    backgroundColor: "#F85A40",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "60px",
    width: "100%",
    color: "#FFFFFF"
  }

  let linkStyle = {
    color: '#FFFFFF'
  }

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <div className="wrapper index">
        {/* <div className="ag-header"></div> */}
        <div className="ag-main">
          <img width={350} style={{ marginTop: '20px' }} src={require('../../assets/images/global.png')} alt="" />

          <section className="login-wrapper">
            <div className="login-header" >
              <p style={textStyle} className="login-subtitle"><b> Headstrt Sessions </b></p>
              {/* <h1 className="login-subtitle">Log in</h1> */}
            </div >
            <div className="login-body">
              <div className="columns">
                <div className="column is-12">
                  <div>
                    <form onSubmit={handleLogin}>
                      <label>
                        <div className="channel-wrapper control has-icons-left">
                          <input name="email" type="email" placeholder="Email" className="ag-rounded input" />
                        </div>
                      </label>

                      <label>
                        <div className="channel-wrapper control has-icons-left">
                          <input name="password" type="password" placeholder="Password" className="ag-rounded input" />
                        </div>
                      </label>
                      <div className="login-footer">

                        <button type="submit" className="ag-rounded button is-info sign-out">Let's Join</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div >
      <footer style={footerStyle} >
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }} className='row'>
          <p> </p>
          <a style={linkStyle} onClick={() => window.open("https://www.about.headstrt.com/", "_blank")} >Home </a>
          <a style={linkStyle} onClick={() => window.open("https://www.about.headstrt.com/safety-guidelines", "_blank")} >Safety Guidelines</a>
          <a style={linkStyle} onClick={() => window.open("https://www.about.headstrt.com/community-guidelines", "_blank")} >Community Guidelines</a>
          <a style={linkStyle} onClick={() => window.open("https://www.about.headstrt.com/expert-guidelines", "_blank")} >Experts Guidelines</a>
          <p> </p>
        </div>
      </footer>
    </div >

  );
};


export default withRouter(Login);