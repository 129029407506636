import * as firebase from "firebase/app";
import 'firebase/auth';
import "firebase/firestore";

var firebaseConfig = {
  // copy and paste your firebase credential here
  apiKey: "AIzaSyDU3kHFa_7YbtLpDnOZC0cK1cZ_19rn1V4",
  authDomain: "headstrt-mobile.firebaseapp.com",
  databaseURL: "https://headstrt-mobile.firebaseio.com",
  projectId: "headstrt-mobile",
  storageBucket: "headstrt-mobile.appspot.com",
  messagingSenderId: "1028357297685",
  appId: "1:1028357297685:web:98db89b5dc0517ad9e601c",
  measurementId: "G-BVX180K8D1"
};

const app = firebase.initializeApp(firebaseConfig);
export const db = app.firestore();

/*
//Google SignIn
const provider = new firebase.auth.GoogleAuthProvider();
clearImmediate

provider.setCustomParameters({
  promt: "select_account",
});
const signInWithGoogle = () =>    auth.signInWithPopup(provider);

*/
//Exports
export const firebaseAuth = firebase.auth();

export default app;

